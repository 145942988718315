<template>
  <v-container>
    <h1>
      <router-link
        :to="{ name: 'Result.Test.GroupList', params: { test_id: testId } }"
        class="link"
        >{{ testName }}</router-link
      >
      result in
      <router-link
        :to="{ name: 'Result.Group.Test', params: { id: groupId } }"
        class="link"
        >{{ groupName }}</router-link
      >
    </h1>
    <div class="d-flex justify-end mb-2">
      <v-btn
        @click="downloadFile(file)"
        color="primary"
        class="mx-1"
        :disabled="file === null"
      >
        {{ $vuetify.lang.t("$vuetify.downloadResult") }}
        <v-icon right dark> mdi-cloud-download </v-icon>
      </v-btn>
      <v-btn
        @click="generateTestResultFile"
        color="primary"
        class="mx-1"
        :loading="generateResultLoading"
      >
        {{ $vuetify.lang.t("$vuetify.generateResult") }}
        <v-icon right dark> mdi-file-cog </v-icon>
      </v-btn>
    </div>
    <data-table
      :headers="headers"
      :items="results"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template
        v-slot:[`item.student_name`]="{ item: { student_id, student_name } }"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :to="{
                name: 'Result.Student.TestResult',
                params: { student_id }
              }"
              class="link"
            >
              <span v-bind="attrs" v-on="on">{{ student_name }}</span>
            </router-link>
          </template>
          <span>{{
            $vuetify.lang.t("$vuetify.checkStudentTestResult", student_name)
          }}</span>
        </v-tooltip>
      </template>
      <template
        v-for="(subject, i) in subjects"
        v-slot:[`item.${subject}.score`]="{ item }"
      >
        <v-menu :key="`${subject} - ${i}`" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              :class="`${parseScoreDisplay(item[subject]).color}--text`"
              >{{ parseScoreDisplay(item[subject]).score }}</span
            >
          </template>
          <v-list
            ><v-list-item
              :inactive="item[subject].id === null"
              :disabled="item[subject].id === null"
              :to="{
                name: 'Subtest.Review',
                params: { id: item[subject].id }
              }"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.reviewSubtest")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="startSubtest(item.id, item[subject].subtest_id)"
              :disabled="item[subject].id !== null"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.startSubtest")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="showForceEndSubtestPrompt(item[subject].id)"
              :disabled="
                (item[subject].id === null && item[subject].score === '-') ||
                  (item[subject].id !== null && item[subject].score !== '-')
              "
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.forceEndSubtest")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="showResetSubtestPrompt(item[subject].id)"
              :disabled="item[subject].id === null"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.resetSubtest")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="recalculateSubtestResult(item[subject].id)"
              :disabled="item[subject].id === null"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.recalculateScore")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="addSubtestDuration(item[subject].id, 15)"
              :disabled="item[subject].id === null"
              link
            >
              <v-list-item-title
                >{{ $vuetify.lang.t("$vuetify.addDuration", "15") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="addSubtestDuration(item[subject].id, 30)"
              :disabled="item[subject].id === null"
              link
            >
              <v-list-item-title
                >{{ $vuetify.lang.t("$vuetify.addDuration", "30") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template
        v-slot:[`item.test_score`]="{ item: { id, test_score, test_end } }"
      >
        <span
          :class="
            `${
              parseScoreDisplay({ id, score: test_score, test_end }).color
            }--text`
          "
          >{{
            parseScoreDisplay({ id, score: test_score, test_end }).score
          }}</span
        >
      </template>
      <template v-slot:[`item.action`]="{ item: { id, file, test_score } }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ $vuetify.lang.t("$vuetify.option") }}
              <v-icon right dark> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="file !== null && downloadFile(file)"
              :disabled="file === null"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.downloadResult")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showResetPrompt(id)" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.resetTest")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="test_score !== '-'"
              @click="showForceEndTestPrompt(id)"
              link
            >
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.forceEndTest")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="file === null"
              @click="renewCertificate(id)"
              link
            >
              <v-list-item-title
                >{{ $vuetify.lang.t("$vuetify.renewCertificate") }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="grey lighten-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <p class="text-center mb-0">
                    {{
                      $vuetify.lang.t("$vuetify.renewCertificateInformation")
                    }}
                  </p>
                  <p class="mb-0">
                    {{
                      $vuetify.lang.t("$vuetify.renewCertificateInformation2")
                    }}
                  </p>
                </v-tooltip></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="addTestDuration(id, 15)" link>
              <v-list-item-title
                >{{ $vuetify.lang.t("$vuetify.addDuration", "15") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="addTestDuration(id, 30)" link>
              <v-list-item-title
                >{{ $vuetify.lang.t("$vuetify.addDuration", "30") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>

    <modal
      v-model="dialog"
      :loading="resetTestLoading"
      :handler="resetTest"
      :persistent="resetTestLoading"
      :title="$vuetify.lang.t('$vuetify.modal.resetTest.title')"
      :description="$vuetify.lang.t('$vuetify.modal.resetTest.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.resetTest.textButton')"
    />

    <modal
      v-model="forceEndTestDialog"
      :loading="forceEndTestLoading"
      :handler="forceEndTest"
      :persistent="forceEndTestLoading"
      :title="$vuetify.lang.t('$vuetify.modal.forceEndTest.title')"
      :description="$vuetify.lang.t('$vuetify.modal.forceEndTest.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.forceEndTest.textButton')"
      :max-width="'320'"
      :negative="true"
    />

    <modal
      v-model="forceEndSubtestDialog"
      :loading="forceEndSubtestLoading"
      :handler="forceEndSubtest"
      :persistent="forceEndSubtestLoading"
      :title="$vuetify.lang.t('$vuetify.modal.forceEndSubtest.title')"
      :description="
        $vuetify.lang.t('$vuetify.modal.forceEndSubtest.description')
      "
      :text-button="
        $vuetify.lang.t('$vuetify.modal.forceEndSubtest.textButton')
      "
      :max-width="'350'"
    />

    <modal
      v-model="resetSubtestDialog"
      :loading="resetSubtestLoading"
      :handler="resetRunningSubtest"
      :persistent="resetSubtestLoading"
      :title="$vuetify.lang.t('$vuetify.modal.resetSubtest.title')"
      :description="$vuetify.lang.t('$vuetify.modal.resetSubtest.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.resetSubtest.textButton')"
      :max-width="'350'"
    />
  </v-container>
</template>

<script>
import { differenceInMinutes } from "date-fns";
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Modal from "@/components/Modal";
import GroupService from "@/services/group";
import ResultService from "@/services/result";
import SubtestService from "@/services/subtest";
import TestService from "@/services/test";

export default {
  name: "Result.Group.TestResult",
  data() {
    return {
      totalData: 0,
      results: [],
      loading: true,
      options: {},
      headers: [],
      subjects: [],
      dialog: false,
      forceEndTestDialog: false,
      forceEndSubtestDialog: false,
      resetSubtestDialog: false,
      resetTestLoading: false,
      resetSubtestLoading: false,
      forceEndTestLoading: false,
      forceEndSubtestLoading: false,
      selectedRunningTestId: "",
      selectedRunningSubtestId: "",
      file: null,
      generateResultLoading: false,
      testName: "",
      groupName: ""
    };
  },
  watch: {
    options: {
      handler() {
        this.getGroupResult();
      },
      deep: true
    }
  },
  components: {
    DataTable,
    Modal
  },
  created() {
    this.setTitle();
    this.findTestResultFile();
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    testId() {
      return this.$route.params.test_id;
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getGroupResult() {
      const result = await ResultService.groupResult(this.groupId, this.testId);
      this.headers = [
        {
          text: this.$vuetify.lang.t("$vuetify.studentName"),
          value: "student_name",
          sortable: false
        },
        ...result.data.data.header.map(({ abbreviation, position }) => ({
          text: abbreviation,
          value: `${abbreviation}-${position}.score`,
          sortable: false
        })),
        {
          text: this.$vuetify.lang.t("$vuetify.score"),
          value: "test_score",
          sortable: false
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action"),
          value: "action",
          sortable: false
        }
      ];
      this.results = [...result.data.data.result];
      this.subjects = [
        ...result.data.data.header.map(
          ({ abbreviation, position }) => `${abbreviation}-${position}`
        )
      ];
      this.loading = false;
    },
    downloadFile(url) {
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.click();
    },
    showResetPrompt(runningTestId) {
      this.selectedRunningTestId = runningTestId;
      this.dialog = true;
    },
    showForceEndTestPrompt(runningTestId) {
      this.selectedRunningTestId = runningTestId;
      this.forceEndTestDialog = true;
    },
    showForceEndSubtestPrompt(runningSubtestId) {
      this.selectedRunningSubtestId = runningSubtestId;
      this.forceEndSubtestDialog = true;
    },
    showResetSubtestPrompt(runningSubtestId) {
      this.selectedRunningSubtestId = runningSubtestId;
      this.resetSubtestDialog = true;
    },
    async resetTest() {
      try {
        this.resetTestLoading = true;
        await TestService.resetRunningTest(this.selectedRunningTestId);
        this.dialog = false;
        await this.refreshData();
      } finally {
        this.resetTestLoading = false;
      }
    },
    async findTestResultFile() {
      const file = await ResultService.testResultFile(
        this.groupId,
        this.testId
      );

      if (file.data.data !== null) {
        this.file = file.data.data.file;
      }
    },
    async generateTestResultFile() {
      try {
        this.generateResultLoading = true;
        const generateResult = await ResultService.generateGroupResult(
          this.groupId,
          this.testId
        );
        this.file = generateResult.data.data.file;
        this.downloadFile(generateResult.data.data.file);
      } finally {
        this.generateResultLoading = false;
      }
    },
    parseScoreDisplay({ id, test_end: testEnd, score }) {
      if (new Date(testEnd) > new Date()) {
        return {
          color: "warning",
          score: `${differenceInMinutes(new Date(testEnd), new Date())}m`
        };
      }

      if (new Date(testEnd) < new Date() && id !== null && score === "-") {
        return {
          color: "red",
          score: "0m"
        };
      }

      return {
        color: "black",
        score
      };
    },
    async forceEndTest() {
      try {
        this.forceEndTestLoading = true;
        await TestService.endTest(this.selectedRunningTestId);
        this.forceEndTestDialog = false;
        await this.refreshData();
      } finally {
        this.forceEndTestLoading = false;
      }
    },
    async forceEndSubtest() {
      try {
        this.forceEndSubtestLoading = true;
        await SubtestService.endTest(this.selectedRunningSubtestId);
        this.forceEndSubtestDialog = false;
        await this.refreshData();
      } finally {
        this.forceEndSubtestLoading = false;
      }
    },
    async resetRunningSubtest() {
      try {
        this.resetSubtestLoading = true;
        await SubtestService.resetRunningSubest(this.selectedRunningSubtestId);
        this.resetSubtestDialog = false;
        await this.refreshData();
      } finally {
        this.resetSubtestLoading = false;
      }
    },
    async setTitle() {
      const group = await GroupService.find(this.groupId);
      this.groupName = group.data.data.name;
      const test = await TestService.find(this.testId);
      this.testName = test.data.data.name;
    },
    async startSubtest(running_test_id, subtest_id) {
      await TestService.startSubtest({
        running_test_id,
        subtest_id
      });
      await this.refreshData();
    },
    async refreshData() {
      this.loading = true;
      await this.getGroupResult();
    },
    async recalculateSubtestResult(runningSubtestId) {
      await ResultService.recalculateSubtestResult({
        running_subtest_id: runningSubtestId
      });
      await this.refreshData();
    },
    async renewCertificate(id) {
      await ResultService.renewCertificate({
        running_test_id: id
      });
      await this.refreshData();
    },
    async addTestDuration(runningTestId, duration) {
      await TestService.addDuration(runningTestId, { amount: duration });
      await this.refreshData();
    },
    async addSubtestDuration(runningSubtestId, duration) {
      await SubtestService.addDuration(runningSubtestId, { amount: duration });
      await this.refreshData();
    }
  }
};
</script>

<style scoped>
.link {
  color: black !important;
  text-decoration: underline;
}
</style>
